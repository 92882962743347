import { useStaticQuery, graphql } from 'gatsby';
import { convertAbsoluteToRelative } from '../helpers/menu-helpers';
import { get } from 'lodash';

export const useTopMenuData = () => {
  const { allMenuLinkContentCityspringsTopMenu } = useStaticQuery(
    graphql`
      query TopMenuQuery {
        allMenuLinkContentCityspringsTopMenu(
          filter: { enabled: { eq: true } }
          sort: { order: ASC, fields: weight }
        ) {
          nodes {
            link {
              uri
            }
            title
            weight
            menu_name
            drupal_parent_menu_item
            drupal_id
            enabled
            field_color
            field_hide_on_mobile
          }
        }
      }
    `
  );

  const menuItems = {};
  for (const menuItem of allMenuLinkContentCityspringsTopMenu.nodes) {
    const key = (menuItem.drupal_parent_menu_item || menuItem.drupal_id).replace('menu_link_content:', '');
    menuItems[key] = menuItems[key] || { children: [] };
    if (menuItem.drupal_parent_menu_item) {
      menuItems[key].children.push({
        title: menuItem.title,
        link: convertAbsoluteToRelative(menuItem.link.uri),
        weight: menuItem.weight || 0,
      });
    }
    else {
      menuItems[key].weight = menuItem.weight || 0;
      menuItems[key].parent = {
        title: menuItem.title,
        link: convertAbsoluteToRelative(menuItem.link.uri),
        color: menuItem.field_color,
        hideOnMobile: menuItem.field_hide_on_mobile || false,
      };
    }
  }

  const sortByWeight = (a, b) => {
    return get(a, 'weight', 0) < get(b, 'weight', 0) ? -1 : 1;
  };

  return Object.values(menuItems)
    .filter((menuItem) => menuItem.parent)
    .map((menuItem) => {
      menuItem.children.sort(sortByWeight);
      return menuItem;
    })
    .sort(sortByWeight);
};
